// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}

// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

h1,h2,h3,h4,h5,h6 {
  margin: 0;
}

.example-highlight>pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;

  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json>pre {
  background: none #fff !important;

}

.example-code>.json {
  background: none #fff !important;
}

.symbol.symbol-45>svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.fab-container {
  position: fixed;
  bottom: 75px;
  right: 35px;
  z-index: 1;

  @media (max-width: 767px) {
    bottom: 90px;
  }
}

.fab {
  margin: 0px 5px !important;
}

.tab-selector {
  display: flex;
  justify-content: center;
  overflow-x: auto;

  @media (max-width: 767px) {
    justify-content: start;
  }
}

.insurance-bg {
  position: absolute;
  width: 100%;
  height: 135px;
  top: 0;
  right: 0;
  z-index: -1;

  @media (max-width: 991px) {
    height: 190px;
  }
}

.img-container {
  width: 60px;
  height: 60px;
}

.ck.ck-editor__editable_inline {
  min-height: 150px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;

  @media (max-width: 767px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.grid-container-mid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.html_editor_wrapper {
  min-height: 300px;
  border-radius: 5px;
  border: 1px solid lightgray;
  font-family: Poppins;
}
.html_editor_content{
  padding: 10px;
  font-family: Poppins;
}